/*  ========================================================================
    # Components - Location Search
    ========================================================================  */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const LocationSearch = ({ locations }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);

  return (
    <form
      className='c-location-search__form'
      onSubmit={event => {
        event.preventDefault();
        if (selectedLocation !== null) {
          window.location.assign(selectedLocation.value);
        }
      }}
    >
      <Select
        defaultValue={selectedLocation}
        onChange={setSelectedLocation}
        options={locations.map(location => ({ label: location.title, value: location.url }))}
        placeholder={'Suchen...'}
        noOptionsMessage={({ inputValue }) =>
          !inputValue ? noOptionsText : 'Kein Ergebnis gefunden.'
        }
        styles={{
          control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? '#e50071' : '#6b6b6a',
            boxShadow: state.isFocused ? '0 0 0 1px #e50071' : '0 0 0 1px #6b6b6a',
            '&:hover': {
              borderColor: '#e50071',
              boxShadow: '0 0 0 1px #e50071'
            }
          })
        }}
      />
      <button className='button' type='submit'>
        Los
      </button>
    </form>
  );
};

LocationSearch.propTypes = {
  locations: PropTypes.array
};

export default LocationSearch;
