/*  ========================================================================
    # Components - Location Search - Index
    ========================================================================  */

import React from 'react';
import { createRoot } from 'react-dom/client';
import LocationSearch from './LocationSearch';

const containers = document.querySelectorAll('div[data-react-app="LocationSearch"]');

for (let i = 0; i < containers.length; i++) {
  const propsAttribute = containers[i].attributes['data-react-props']?.value || '{}';
  const props = JSON.parse(propsAttribute) || {};

  createRoot(containers[i]).render(<LocationSearch {...props} />);
}
